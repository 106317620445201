// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h46H7eFG8cKMRtlZeYz7{border-radius:8px;width:100%}", "",{"version":3,"sources":["webpack://./src/components/atoms/FullImage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA","sourcesContent":[".image {\n  border-radius: 8px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "h46H7eFG8cKMRtlZeYz7"
};
export default ___CSS_LOADER_EXPORT___;
