import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import EditPhoto from '~/components/organisms/EditPhoto';
import { CreateAffirmationCategoryType } from '~/modules/affirmationCategories/types';
import { GroupAffirmationCategoryType } from '~/modules/groupsAffirmationsCategories/types';
import { resizeFile } from '~/services/File';

import styles from './styles.module.scss';

type FormDataType = CreateAffirmationCategoryType;

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    group_id: yup.string().required('Required'),
  })
  .required();

type Props = {
  onClose: () => void;
  addCategory: (data: CreateAffirmationCategoryType) => Promise<void>;
};

const CreateImprovementAreaForm: FC<Props> = ({ onClose, addCategory }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackBarContext();
  const [groups, setGroups] = useState<GroupAffirmationCategoryType[]>([]);
  const loadGroups = async (): Promise<void> => {
    try {
      const groups = await Api.getGroupsAffirmationCategory({
        query: { 'per-page': 1000 },
      });
      setGroups(groups.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadGroups();
  }, []);

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit, watch, setValue } = form;

  const handleUploadPhoto = async (image: File): Promise<void> => {
    try {
      setIsLoading(true);
      const resizedImage = await resizeFile(image, 240, 240, 10);
      const [src, preview_src] = await Promise.all([
        Api.uploadMediaFile(image),
        Api.uploadMediaFile(resizedImage),
      ]);
      setValue('image_src', src);
      setValue('preview_image_src', preview_src);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      await addCategory(data);
      setIsLoading(false);
      onClose();
    } catch (e: any) {
      showSnackbar(e.message || 'Error :<');
      setIsLoading(false);
    }
  };

  const image_src = watch('image_src');
  return (
    <div className={styles.main_container}>
      <ModalContentTitle text="New Category" />
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(handleSuccessSubmit)}>
          <div style={{ marginBottom: '8px' }}>
            <EditPhoto
              isLoading={isLoading}
              src={image_src}
              update={handleUploadPhoto}
            />
            <HookFormTextField
              required
              hidden
              style={{ display: 'none' }}
              name="image_src"
              disabled={isLoading}
              fullWidth
            />
            <HookFormTextField
              required
              hidden
              style={{ display: 'none' }}
              name="preview_image_src"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormTextField
              required
              name="name"
              label="Category Name"
              disabled={isLoading}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormSelectField
              required
              name="group_id"
              label="Group"
              disabled={isLoading}
              fullWidth
              options={groups.map(({ id, name }) => ({
                value: id,
                text: name,
              }))}
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormCheckbox
              required
              name="is_premium"
              label="Premium"
              disabled={isLoading}
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormCheckbox
              required
              name="is_new"
              label="New"
              disabled={isLoading}
            />
          </div>
          <div style={{ marginBottom: '8px' }}>
            <HookFormCheckbox
              required
              name="is_visible"
              label="Visible"
              disabled={isLoading}
            />
          </div>
          <div className={styles.buttons_container}>
            <Button
              disabled={isLoading}
              color="primary"
              variant="outlined"
              onClick={(): void => onClose()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              fullWidth
            >
              Add Group
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateImprovementAreaForm;
