import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Icon } from '@iconify/react';
import { readAndCompressImage } from 'browser-image-resizer';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import Api from '~/Api';
import BackButton from '~/components/atoms/buttons/BackButton';
import Button from '~/components/atoms/buttons/Button';
import FileUploadButton from '~/components/atoms/buttons/FileUploadButton';
import IconButton from '~/components/atoms/buttons/IconButton';
import FullImage from '~/components/atoms/FullImage';
import Loader from '~/components/atoms/Loader';
import HookFormCheckbox from '~/components/hookFormControls/CheckboxControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import HookFormToggler from '~/components/hookFormControls/TogglerControl';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import useFilters from '~/hooks/useFilters';
import useQueryParams from '~/hooks/useQueryParams';
import useData, {
  FiltersType,
} from '~/modules/themeCategories/components/ThemeCategoriesList/useData';
import { ThemeCategoryType } from '~/modules/themeCategories/types';
import { ThemesListRoute } from '~/modules/themes';
import { fonts } from '~/modules/themes/constants';
import { ThemeType } from '~/modules/themes/types';
import DateService from '~/services/Date';
import { SORT_ORDER } from '~/types/common';

import { FontObject } from '../ThemesList/CreateForm';
import styles from './styles.module.scss';

type FormDataType = Omit<ThemeType, 'id'>;

const DEFAULT_FONT: FontObject = {
  key: 'Inter-Regular',
  title: 'Inter - Regular',
  font: {
    family: 'Inter',
    weight: 400,
  },
};

const DEFAULT_FILTER_STATE: FiltersType = {
  sortOrder: SORT_ORDER.DESC,
  sortBy: 'created_at',
  page: 1,
  'per-page': 10,
};

const formSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    color: yup.string(),
    asset_src: yup.string().required('Required'),
    font_name: yup.string().required('Required'),
    font_color: yup.string().required('Required'),
    theme_category_id: yup.string().required('Required'),
    is_premium: yup.boolean().required('Required'),
    is_new: yup.boolean().required('Required'),
  })
  .required();

const ThemeItem: FC = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<ThemeCategoryType[]>([]);
  const [previewImg, setPreviewImg] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [assetSrc, setAssetSrc] = useState<string>('');
  const [previewAssetSrc, setPreviewAssetSrc] = useState<string>('');

  const [color, setColor] = useState<string>('');
  const [selectedFont, setSelectedFont] = useState<FontObject>(DEFAULT_FONT);
  const [iconsColor, SetIconsColor] = useState<'White' | 'Black' | ''>('');
  const [theme, setTheme] = useState<ThemeType>({
    id: '1',
    name: '',
    is_premium: true,
    color: '',
    is_new: true,
    asset_src: '',
    preview_asset_src: '',
    font_color: '',
    font_name: '',
    position: 1,
    theme_category_id: '',
    category_name: '',
    created_at: '2021-01-01',
    updated_at: '2021-01-01',
  });

  const { getQueryParams } = useQueryParams();

  const defaultFilterState = {
    ...DEFAULT_FILTER_STATE,
    ...getQueryParams(),
  };

  const { filters } = useFilters<FiltersType>(defaultFilterState);

  const { updateItems } = useData(filters);

  const { showSnackbar } = useSnackBarContext();

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      is_premium: false,
      color: '',
      is_new: true,
      asset_src: '',
      font_color: '',
      font_name: '',
      theme_category_id: '',
      category_name: '',
      position: 1,
    },
  });

  const { handleSubmit, watch, setValue } = form;

  const generateFilename = (): string => {
    const date = new Date();
    return `preview_${date.getTime()}.jpg`;
  };

  const loadCategories = async (): Promise<void> => {
    try {
      const data = await Api.getThemeCategories({
        query: { 'per-page': 1000 },
      });
      setCategories(data.data);
    } catch (e: any) {
      showSnackbar(e.message);
    }
  };

  useEffect(() => {
    setValue('name', theme.name);
    setValue('position', theme.position);
    setValue('font_name', theme.font_name);
    setValue('font_color', theme.font_color);
    setValue('is_new', theme.is_new);
    setValue('is_premium', theme.is_premium);
    setValue('asset_src', theme.asset_src);
    setValue('theme_category_id', theme.theme_category_id);

    if (theme.color === '#ffffff') {
      setValue('color', theme.color);
      SetIconsColor('White');
    } else {
      SetIconsColor('Black');
    }

    const selectedCategory = categories.find(
      (cat) => cat.id === theme.theme_category_id,
    );
    if (selectedCategory) {
      setValue('category_name', selectedCategory?.name);
    }

    const fontFromTheme = fonts.find((f) => f.key === theme.font_name);
    if (fontFromTheme) {
      setSelectedFont(fontFromTheme);
    }

    if (theme.font_color) {
      setColor(theme.font_color);
    }
  }, [theme]);

  const loadTheme = async (id: string): Promise<void> => {
    try {
      setIsLoading(true);
      const theme = await Api.getTheme(id);
      setTheme(theme);
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    const modifiedData = { ...data };
    modifiedData.position = Number(data.position);

    if (iconsColor === 'Black') {
      modifiedData.color = '#000000';
    } else {
      modifiedData.color = '#ffffff';
    }

    const selectedCategory = categories.find(
      (cat) => cat.id === data.theme_category_id,
    );

    if (selectedCategory) {
      modifiedData.category_name = selectedCategory.name;
    }

    modifiedData.asset_src = assetSrc;
    modifiedData.preview_asset_src = previewAssetSrc;

    try {
      setIsLoading(true);

      const theme = await Api.updateTheme(id, modifiedData);
      showSnackbar('Updated', 'success');
      setTheme(theme);
      setIsLoading(false);
      history.push(ThemesListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  useEffect(() => {
    if (!id) {
      history.push('/');
      return;
    }
    Promise.all([loadCategories(), loadTheme(id)]).finally(() =>
      setIsFirstLoading(false),
    );
  }, [id]);

  const handleUpdatePhoto = async (file: File): Promise<void> => {
    setIsLoading(true);
    const reader = new FileReader();
    if (reader) {
      reader.onloadend = (): void => {
        setPreviewImg(reader.result);
      };
      reader.readAsDataURL(file);
    }
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('data', file);

      const res = await Api.uploadThemeBackground(file);
      setAssetSrc(res.data);

      showSnackbar('Background is added', 'success');
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message, 'error');
    }

    try {
      const compressConfig = {
        quality: 0.3,
        maxHeight: 200,
      };
      const compressedImageBlob = await readAndCompressImage(
        file,
        compressConfig,
      );

      const fileName = generateFilename();
      const compressedImageFile = new File([compressedImageBlob], fileName, {
        type: 'image/jpeg',
        lastModified: new Date().getTime(),
      });

      const formData = new FormData();
      formData.append('image', compressedImageFile);

      const res = await Api.uploadThemeBackground(compressedImageFile);
      setPreviewAssetSrc(res.data);
      await updateItems();
      showSnackbar('Background preview is added', 'success');
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message);
      console.error(e);
    }
  };

  const handleRemovePhoto = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.removeFile();
      setValue('asset_src', '');
      showSnackbar('Removed', 'success');
      setIsLoading(false);
    } catch (e: any) {
      showSnackbar(e.message, 'error');
    }
  };

  const handleRemoveTheme = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.deleteTheme(id);
      showSnackbar('Removed', 'success');
      setIsLoading(false);
      history.push(ThemesListRoute.path);
    } catch (e: any) {
      showSnackbar(e.message);
      setIsLoading(false);
      history.push('/');
    }
  };

  React.useEffect(() => {
    const subscription = watch(() => {
      const watchAllFields = watch();
      if (watchAllFields.font_name) {
        const selectedFontObject = fonts.find(
          (font) => font.key === watchAllFields.font_name,
        );

        if (selectedFontObject) {
          setSelectedFont(selectedFontObject);
        }
      }

      if (watchAllFields.font_color) {
        setColor(watchAllFields.font_color);
      }

      if (!watchAllFields.color) {
        SetIconsColor('Black');
      } else {
        SetIconsColor('White');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <BackButton
        className={styles.back_button}
        text="Back to List"
        onClick={(): void => {
          history.push(ThemesListRoute.path);
        }}
      />
      <TopHeadlineRow className={styles.top_bar} text="Theme card" />
      <div className={styles.container}>
        <div>
          <Loader isLoading={isFirstLoading}>
            <Tabs
              className={styles.container__tabs}
              items={[
                {
                  label: 'General info',
                  isActive: true,
                },
              ]}
              onClickItem={(): void => {}}
            />
            <div className={styles.form_container}>
              <FormProvider {...form}>
                <form>
                  <div className={styles.form_container__field}>
                    <div className={styles.field_wrapper}>
                      <div className={styles.container}>
                        <div className={styles.container__item}>
                          <FileUploadButton
                            onChange={(e): void => {
                              if (e?.target?.files && e.target.files[0]) {
                                handleUpdatePhoto(e.target.files[0]);
                              }
                            }}
                            variant="outlined"
                          >
                            Upload photo
                          </FileUploadButton>
                        </div>
                        {Boolean(previewImg) && (
                          <div className={styles.container__item}>
                            <IconButton
                              size="medium"
                              variant="outlined"
                              disabled={isLoading}
                              onClick={handleRemovePhoto}
                            >
                              <Icon color="#f00" icon="heroicons:trash" />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormTextField
                        required
                        name="name"
                        label="Name"
                        disabled={isLoading}
                        fullWidth
                      />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormTextField
                        required
                        name="position"
                        label="Position"
                        disabled={isLoading}
                        fullWidth
                        type="number"
                      />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormCheckbox name="is_premium" label="Is premium?" />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormToggler
                        name="color"
                        label={`${iconsColor} icons color`}
                        disabled={isLoading}
                        type="color"
                      />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormCheckbox name="is_new" label="Is new?" />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormTextField
                        required
                        name="asset_src"
                        label="Asset Src"
                        disabled={isLoading}
                        fullWidth
                      />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormTextField
                        required
                        name="font_color"
                        label="Font Color"
                        disabled={isLoading}
                        fullWidth
                        type="color"
                      />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormSelectField
                        required
                        name="font_name"
                        label="Font"
                        disabled={isLoading}
                        fullWidth
                        options={fonts.map((value) => ({
                          text: value.title,
                          value: value.key,
                        }))}
                      />
                    </div>
                    <div className={styles.field_wrapper}>
                      <HookFormSelectField
                        required
                        name="theme_category_id"
                        label="Category"
                        disabled={isLoading}
                        fullWidth
                        options={categories.map((value) => ({
                          text: value.name,
                          value: value.id,
                        }))}
                      />
                    </div>
                  </div>

                  <div className={styles.extra_info}>
                    <div className={styles.extra_info__title}>
                      <div>Created at:</div>
                      <div>Updated at:</div>
                    </div>
                    <div className={styles.extra_info__data}>
                      <div>
                        {DateService.format(
                          new Date(theme.created_at),
                          READABLE_DATE_TIME_FORMAT,
                        )}
                      </div>
                      <div>
                        {DateService.format(
                          new Date(theme.updated_at),
                          READABLE_DATE_TIME_FORMAT,
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.buttons_container}>
                    <Button
                      type="submit"
                      disabled={isLoading}
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit(handleSuccessSubmit)}
                    >
                      Update info
                    </Button>
                    <Button
                      type="submit"
                      disabled={isLoading}
                      color="secondary"
                      variant="contained"
                      onClick={handleRemoveTheme}
                    >
                      Delete Theme
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </Loader>
        </div>

        <div className={styles.image_container}>
          {assetSrc ? (
            <div className={styles.image}>
              <FullImage imgSrc={String(assetSrc)} />
              <p
                className={styles.text}
                style={{
                  color: color,
                  fontFamily: selectedFont.font.family,
                  fontWeight: selectedFont.font.weight
                    ? selectedFont.font.weight
                    : undefined,
                  fontStyle: selectedFont.font.italic ? 'italic' : undefined,
                }}
              >
                Preview Affirmation Text
              </p>
            </div>
          ) : (
            <div className={styles.image}>
              <FullImage imgSrc={String(theme.asset_src)} />
              <p
                className={styles.text}
                style={{
                  color: color,
                  fontFamily: selectedFont.font.family,
                  fontWeight: selectedFont.font.weight
                    ? selectedFont.font.weight
                    : undefined,
                  fontStyle: selectedFont.font.italic ? 'italic' : undefined,
                }}
              >
                Preview Affirmation Text
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThemeItem;
