// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W1dRxmIz_dH1IwBdF68r{padding:20px 30px;display:flex;flex-direction:row;gap:40px}.o5y5krjYGzNVJv1ku7Er{width:400px}.WpK9WG8iVAFPSi8917SU{display:flex;justify-content:space-between;flex-wrap:nowrap;margin-top:32px}.WpK9WG8iVAFPSi8917SU>*:first-child{margin-right:10px}.pfC_wGdLr6QkP9HY2Pdo{margin-bottom:8px}.lf7wBkzrYLKbootgC2cu{width:auto;max-width:600px;height:auto;max-height:100vh;position:relative}.UgFK6DErNMKd0yWA2JI9{position:relative;width:100%}.e20WEngRad7czqmwh5Ba{position:absolute;margin:0;top:50%;transform:translateY(-50%);font-size:23px;line-height:1.3;text-align:center;padding:0 20px;width:100%}", "",{"version":3,"sources":["webpack://./src/modules/themes/components/ThemesList/CreateForm/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,gBAAA,CACA,eAAA,CAEA,oCACE,iBAAA,CAIJ,sBACE,iBAAA,CAGF,sBACE,UAAA,CACA,eAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,0BAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,cAAA,CACA,UAAA","sourcesContent":[".container {\n  padding: 20px 30px;\n  display: flex;\n  flex-direction: row;\n  gap: 40px;\n}\n\n.main_container {\n  width: 400px;\n}\n\n.buttons_container {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: nowrap;\n  margin-top: 32px;\n\n  & > *:first-child {\n    margin-right: 10px;\n  }\n}\n\n.field_wrapper {\n  margin-bottom: 8px;\n}\n\n.image_container {\n  width: auto;\n  max-width: 600px;\n  height: auto;\n  max-height: 100vh;\n  position: relative;\n}\n\n.image {\n  position: relative;\n  width: 100%;\n}\n\n.text {\n  position: absolute;\n  margin: 0;\n  top: 50%;\n  transform: translateY(-50%);\n  font-size: 23px;\n  line-height: 1.3;\n  text-align: center;\n  padding: 0 20px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "W1dRxmIz_dH1IwBdF68r",
	"main_container": "o5y5krjYGzNVJv1ku7Er",
	"buttons_container": "WpK9WG8iVAFPSi8917SU",
	"field_wrapper": "pfC_wGdLr6QkP9HY2Pdo",
	"image_container": "lf7wBkzrYLKbootgC2cu",
	"image": "UgFK6DErNMKd0yWA2JI9",
	"text": "e20WEngRad7czqmwh5Ba"
};
export default ___CSS_LOADER_EXPORT___;
